<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="currentData.ResponsiblePersonPosition"
              class="company-details__input"
              placeholder="Должность"
            />
          </div>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="currentData.ResponsiblePersonFullName"
              class="company-details__input"
              placeholder="ФИО ответственного лица"
            />
          </div>
          <span>
            {{ customerData.Name }}
          </span>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">
          Прошу вернуть ошибочно перечисленные денежные средства, согласно акту сверки на
        </span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="currentData.ReconciliationReportDate"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">в размере</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="currentData.MoneyAmount"
            class="company-details__input"
            placeholder="Введите сумму"
          />
        </div>
        <span class="statement-text">руб., по нижеследующим реквизитам:</span>
      </div>
      <br />
      <p>
        {{ customerData.Name }}
      </p>
      <div v-for="item in userFormDetails" :key="item.label" class="dflex valign-center">
        <span>{{ item.label }}</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="item.value"
            class="company-details__input"
            :placeholder="item.placeholder"
          />
        </div>
      </div>

      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="currentData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="currentData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapState } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementTitle: 'Заявление',
      userFormData: {},
      userFormDetails: {}
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    ServicesDescription
  },

  mounted() {
    this.userFormData = { ...this.currentData };
    this.userFormDetails = { ...this.userDetails };
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData
    }),

    currentData() {
      return {
        ContractNumber: this.customerData.ContractNumber,
        ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
        ChiefPosition: '',
        ChiefFullName: '',
        ResponsiblePersonFullName: '',
        ResponsiblePersonPosition: '',
        ReconciliationReportDate: ShowCurrentDate(),
        MoneyAmount: ''
      };
    },

    userDetails() {
      return [
        { label: 'ИНН/КПП:', value: this.customerData.Inn, placeholder: 'ИНН/КПП' },
        { label: 'р/с: ', value: this.customerData.Rch, placeholder: 'р/с' },
        {
          label: 'Наименование банка: ',
          value: this.customerData.Bank,
          placeholder: 'Наименование банка'
        },
        { label: 'БИК: ', value: this.customerData.Bik, placeholder: 'БИК' },
        { label: 'к/с: ', value: this.customerData.Kch, placeholder: 'к/с' }
      ];
    }
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      const [Inn, Rch, Bank, Bik, Kch] = this.userFormDetails;

      return {
        CurrentCustomer: {
          ContractNumber: this.currentData.ContractNumber,
          ChiefPosition: this.currentData.ChiefPosition,
          ChiefFullName: this.currentData.ChiefFullName,
          Inn: Inn.value,
          Rch: Rch.value,
          Bank: Bank.value,
          Bik: Bik.value,
          Kch: Kch.value
        },
        ResponsiblePersonFullName: this.currentData.ResponsiblePersonFullName,
        ResponsiblePersonPosition: this.currentData.ResponsiblePersonPosition,
        Refund: {
          ReconciliationReportDate: `${this.currentData.ReconciliationReportDate}T00:00:00`,
          MoneyAmount: this.currentData.MoneyAmount
        }
      };
    },

    getDocument() {
      this.createDocument(this.createModelToSend());
    }
  }
};
</script>

<style></style>
